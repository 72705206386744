.container {
	width: 100%;
	height: 100vh;
	background: linear-gradient(
		180deg,
		#f1375a 0%,
		#f26052 50.39%,
		#f9a978 100%
	);
	display: flex;
	justify-content: center;
	align-items: center;

	> .menuContainer {
		position: fixed;
		top: 0;
	}

	> .formContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 557px;
		border-radius: 10px;
		background-color: #ffffff;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
		padding: 45px 0;

		> .introduction {
			color: #9799a0;
			font-family: Mulish;
			font-size: 18px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 30px;
			text-align: center;
			width: 340px;
			margin-bottom: 51px;
		}

		> .message {
			color: #6d6e71;
			font-family: Mulish;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 30px;
			text-align: center;
			width: 340px;
		}

		& input {
			width: 365px;
		}

		> .textFieldContainer {
			margin: 51px 0;
			display: flex;
			flex-direction: column;

			> .textField {
				height: 50px;
				border: 1px solid #6d6e71;
				border-radius: 5px;
				color: darken(#6d6e71, 10%);
				font-family: Mulish;
				font-size: 18px;
				letter-spacing: 0;
				line-height: 23px;
				box-sizing: border-box;
				padding: 0 18px;

				&:focus-visible {
					outline-color: #6d6e71;
				}

				&::placeholder {
					color: #6d6e71;
				}
			}

			> .errorMessage {
				width: 365px;
				color: #1f415a;
				font-family: Mulish;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 18px;
				margin-top: 15px;
				height: 0;
			}
		}

		> .button {
			border: 0;
			border-radius: 5px;
			background-color: #f03c5a;
			height: 50px;
			color: #ffffff;
			font-family: Mulish;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 25px;
			text-align: center;
			cursor: pointer;

			&:hover {
				background-color: lighten(#f03c5a, 10%);
			}
		}
	}
}
