@import "../../assets/styles/global.scss";

.container {
	width: $width;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 50px;
	padding-bottom: 50px;

	&.withDarkFilter {
		img {
			filter: invert(44%) sepia(1%) saturate(1255%) hue-rotate(188deg)
				brightness(97%) contrast(92%);
		}
	}

	> .menuContainer {
		background-color: white;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 116px 139px;

		> .title {
			color: #ee3736;
			font-family: Prata;
			font-size: 36.5px;
			letter-spacing: 0;
			line-height: 59px;
		}

		> .content {
			margin-top: 44px;
			margin-left: 44px;
			color: #ee3736;
			font-family: Prata;
			font-size: 29.5px;
			letter-spacing: 0;
			line-height: 57px;

			> .option {
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		> .close {
			position: absolute;
			top: 35px;
			right: 35px;
			cursor: pointer;
		}
	}

	.containerLeftSide {
		.ozLogoContainer {
			width: 247px;
			height: 54px;
		}
	}

	.containerRightSide {
		display: flex;
		flex-direction: row;
		align-items: center;

		.myovantLogoContainer {
			margin-left: 48px;
		}

		.menuLogoContainer {
			margin-left: 112px;
			cursor: pointer;
		}
	}
}
