@import "../../../assets/styles/global.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.firstSection {
		background: linear-gradient(
			180deg,
			#f1375a 0%,
			#f26052 50.39%,
			#f9a978 100%
		);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.illuminatingFullPicture {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100vh;
			scroll-snap-align: start;

			.title {
				margin-top: 20vh;
				width: $width;
				color: #ffffff;
				font-size: 89.5px;
				letter-spacing: 0;
				line-height: 106px;
				text-align: left;
			}

			.subtitle {
				margin: 0;
				margin-top: 3vh;
				width: $width;
				color: #ffffff;
				font-size: 45px;
				letter-spacing: 0;
				line-height: 73px;
				text-align: left;
			}

			.date {
				width: $width;
				color: #fff0be;
				font-family: Mulish;
				font-size: 24.5px;
				letter-spacing: 8.81px;
				line-height: 31px;
				text-align: left;
				text-transform: uppercase;
			}

			.scrollDownImageContainer {
				width: $width;
				text-align: center;
				margin-top: 12vh;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.introSection {
			min-height: 100vh;

			.introduction {
				padding-top: 100px;
				margin-bottom: 67px;
				height: 44px;
				width: $width;
				color: #ffffff;
				font-family: Mulish;
				font-size: 34.5px;
				letter-spacing: 12.4px;
				line-height: 44px;
				text-transform: uppercase;
			}
		}

		.phrase {
			height: 100vh;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			scroll-snap-align: start;
		}

		.chapter1 {
			height: 100vh;
			scroll-snap-align: start;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.sheIsStrong {
			background-image: url(../../../assets/img/she_is_strong.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 1620px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			scroll-snap-align: center;

			&InternalContainer {
				height: inherit;
				width: $width;
				position: relative;
			}
		}

		.sheFeels {
			width: $width;
			text-align: left;

			p {
				color: #ffffff;
				font-family: Mulish;
				font-size: 42.6px;
				letter-spacing: 0;
				line-height: 54px;
				width: 1000px;
			}
		}

		.sheApproachesContainer {
			background-image: url(../../../assets/img/hands_vientre.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 2100px;
			width: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			&InternalContainer {
				height: inherit;
				width: $width;
				position: relative;

				.sheApproaches {
					width: $width;
					text-align: left;

					p {
						color: #ffffff;
						font-family: Mulish;
						font-size: 42.6px;
						letter-spacing: 0;
						line-height: 58px;
						width: 850px;
					}
				}

				.sheApproachesSignature {
					height: 1200px;
					width: $width;
					position: relative;
					scroll-snap-align: center;
				}
			}
		}
	}

	.secondSection {
		background-color: #00677f;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.firstVideo {
			width: 100%;
			background-color: black;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			.sheApproachesVideoContainer {
				display: flex;
				width: 100%;
				flex-direction: column;
				align-items: center;
				position: relative;

				.sheApproachesVideo {
					height: 2500px;
					width: $width + 50px;
					padding-top: 20px;
					position: absolute;
				}
			}

			.cloudsContainer {
				background: linear-gradient(
					180deg,
					transparent 0%,
					#00677f 100%
				);
				width: 100%;

				.clouds {
					background-image: url(../../../assets/img/clouds.png);
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					height: 3625px;
					margin-top: 800px;
					width: 100%;
					z-index: 100;
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;

					.phrase2 {
						margin-top: 1220px;
						margin-bottom: 260px;
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						scroll-snap-align: center;
					}

					.chapterTwo {
						height: 100vh;
						display: flex;
						flex-direction: column;
						justify-content: center;
						width: $width;
						z-index: 150;
						scroll-snap-align: center;
					}
				}
			}
		}

		.sheStrugglesContainer {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			position: relative;
			background-color: #00677f;

			.sheStrugglesStickyContainer {
				width: $width;

				.sheStruggles {
					width: $width;
					text-align: left;
					padding-top: 20px;

					p {
						color: #ffffff;
						font-family: Mulish;
						font-size: 42.6px;
						letter-spacing: 0;
						line-height: 58px;
						width: 850px;
					}
				}
			}

			.strugglesContainer {
				background: linear-gradient(
					180deg,
					transparent 0%,
					#00677f 100%
				);
				width: 100%;

				.struggles {
					background-image: url(../../../assets/img/drowning.png);
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					height: 2000px;
				}
			}
		}

		.galleryContainer {
			overflow: hidden;
			width: 100%;
		}

		.bellowGallery {
			width: $width;
			color: #ffffff;
			font-family: Mulish;
			font-size: 17px;
			letter-spacing: 1.6px;
			line-height: 25px;
			text-transform: uppercase;
			margin-bottom: 125px;

			.text {
				margin-left: 150px;
				width: 616px;
				cursor: pointer;
			}
		}

		.emotionalJourneyContainer {
			background-image: url(../../../assets/img/freedom.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 2000px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.emotionalJourneyContainerInternal {
				width: $width;
				display: flex;
				height: inherit;
				flex-direction: column;
				justify-content: space-between;

				.phrase3 {
					height: 100vh;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					scroll-snap-align: start;
					justify-content: center;
				}

				.emotionalJourneyPhraseContainer {
					margin-bottom: 60px;

					p {
						width: 722px;
						color: #ffffff;
						font-family: Prata;
						font-size: 69.5px;
						letter-spacing: 0;
						line-height: 79px;
					}

					.emotionalButtonContainer {
						width: 380px;
					}
				}
			}
		}

		.menstruation {
			width: 100%;
			height: 100vh;
			display: flex;
			flex-direction: row;
			scroll-snap-align: start;

			.imageContainer {
				background-position: bottom;
				background-repeat: no-repeat;
				background-size: cover;
				flex: 1;
			}

			.imageContainer.before {
				background-image: url(../../../assets/img/before_menstruation.jpg);
			}

			.imageContainer.during {
				background-image: url(../../../assets/img/during_menstruation.jpg);
			}

			.imageContainer.after {
				background-image: url(../../../assets/img/after_menstruation.jpg);
			}

			.textContainer {
				flex: 1;
				width: calc(#{$width}/ 2);
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 50px;
				padding-bottom: 50px;

				.title {
					color: #ffffff;
					font-family: Prata;
					font-size: 69.5px;
					letter-spacing: 0;
					line-height: 79px;
					padding-left: 83px;
					padding-right: 83px;
				}

				.columns {
					display: flex;
					justify-content: space-between;
					flex: 1;

					> :first-of-type {
						padding-left: 83px;
					}

					> :last-of-type {
						padding-right: 83px;
					}

					.column {
						margin: 52px 0;
						flex: 1;
						justify-content: space-between;
						display: flex;
						flex-direction: column;
						max-width: 80%;
						margin-right: 20px;

						.columnTitle {
							color: #fff0be;
							font-family: Mulish;
							font-size: 32px;
							font-weight: 500;
							letter-spacing: 0;
							line-height: 32px;
						}

						.columnText {
							color: #ffffff;
							font-family: Prata;
							font-size: 33.5px;
							letter-spacing: 0;
							line-height: 54px;
						}
					}
				}

				.buttonContainer {
					max-width: 466px;
					padding-left: 83px;
					padding-right: 83px;
					margin-bottom: 24px;
				}

				.songsTitle {
					color: #fff0be;
					font-family: Mulish;
					font-size: 23.5px;
					letter-spacing: -0.3px;
					line-height: 40px;
					padding-left: 83px;
					padding-right: 83px;
				}

				.songs {
					display: flex;
					padding-left: 83px;
					padding-right: 83px;
					justify-content: space-between;

					.song {
						flex: 1;
						margin-top: 13px;
						max-width: 45%;
					}
				}
			}
		}

		.livingInParadoxContainer {
			background-image: url(../../../assets/img/two_faces.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 1760px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.livingInParadoxContainerInternal {
				width: $width;
				display: flex;
				height: inherit;
				flex-direction: column;
				justify-content: space-between;

				.titleAndDescriptionContainer {
					margin-top: 262px;
					width: 650px;

					.title {
						color: #ffffff;
						font-family: Prata;
						font-size: 69.5px;
						letter-spacing: 0;
						line-height: 79px;
					}

					.description {
						margin-top: 45px;
						width: 400px;
						color: #ffffff;
						font-family: Mulish;
						font-size: 42.6px;
						letter-spacing: 0;
						line-height: 58px;
					}
				}

				.phrase4 {
					margin-bottom: 130px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}
			}
		}

		.sheHasLearnedContainer {
			width: $width;

			.sheHasLearned {
				width: 850px;
				margin-top: 170px;
				margin-bottom: 125px;

				.text {
					color: #ffffff;
					font-family: Mulish;
					font-size: 42.6px;
					letter-spacing: 0;
					line-height: 58px;
				}
			}
		}

		.turnCardContainer {
			width: 100%;
			height: 100vh;
			scroll-snap-align: start;
		}

		.sheIsNotHeardContainer {
			background-image: url(../../../assets/img/she_is_not_heard.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.sheIsNotHeardContainerInternal {
				width: $width;
				display: flex;
				height: inherit;
				flex-direction: column;
				justify-content: space-between;

				.text {
					margin-top: 300px;
					width: 895px;
					color: #ffffff;
					font-family: Mulish;
					font-size: 42.6px;
					letter-spacing: 0;
					line-height: 58px;
				}

				.tedTalkContainer {
					margin-top: 800px;
					display: flex;
					flex-direction: column;

					.title {
						color: white;
						text-transform: uppercase;
						font-family: Mulish;
						font-size: 29px;
						margin-bottom: 50px;
					}

					.subtitle {
						color: white;
						font-family: Prata;
						font-size: 64px;
					}

					.text {
						margin-top: 50px;
						margin-bottom: 100px;
						width: 1100px;
						color: #ffffff;
						font-family: Mulish;
						font-size: 42.6px;
						letter-spacing: 0;
						line-height: 58px;
					}
				}
			}
		}
	}

	.thirdSection {
		background: linear-gradient(
			0deg,
			#c7eafb 0%,
			#c5e8f9 24.62%,
			#bde2f4 36.62%,
			#b0d9ec 45.89%,
			#9ecde4 53.77%,
			#86bfda 60.75%,
			#6ab0ce 67%,
			#48a2c3 72.06%,
			#4391af 76.95%,
			#376e84 86.2%,
			#1f5c6e 98.74%
		);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.secondVideo {
			width: $width + 50px;
			background-color: black;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
		}

		.cloudsAndFeatherContainer {
			width: 100%;

			.cloudsAndFeather {
				background-image: url(../../../assets/img/clouds_feather.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				width: 100%;
				z-index: 100;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;

				.phrase3 {
					height: 100vh;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-end;
				}

				.chapterThree {
					height: 100vh;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: $width;
					z-index: 150;
					scroll-snap-align: start;
				}
			}
		}

		.millenialMomContainer {
			background-image: url(../../../assets/img/facing_the_bull.png);
			background-position: right;
			background-repeat: no-repeat;
			background-size: contain;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.millenialMomContainerInternal {
				padding-top: 380px;
				width: $width;
				height: 1894px;

				.text {
					width: 872px;
					color: #5d6e73;
					font-family: Mulish;
					font-size: 42.6px;
					letter-spacing: 0;
					line-height: 58px;
				}
			}
		}

		.opportunityToBeFreeContainer {
			background-image: url(../../../assets/img/peace.png);
			background-position: right;
			background-repeat: no-repeat;
			background-size: contain;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.opportunityToBeFreeContainerInternal {
				padding-top: 50px;
				padding-bottom: 150px;
				width: $width;

				:first-child {
					margin-bottom: 70px;
				}

				.text {
					width: 740px;
					color: #5d6e73;
					font-family: Mulish;
					font-size: 42.6px;
					letter-spacing: 0;
					line-height: 58px;
				}
			}
		}

		.thirdVideo {
			width: $width + 50px;
			background-color: black;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
		}

		.patientsGallery {
			width: $width;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 100px;

			.patientsGalleryContainer {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				.text {
					width: 545px;
					color: #ffffff;
					font-family: Prata;
					font-size: 58.5px;
					letter-spacing: 0;
					line-height: 61px;
					margin-left: 30px;
					margin-right: 30px;
				}

				.patientImageContainer {
					width: 135px;
					height: 135px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 28px;
					margin-left: 30px;
					margin-right: 30px;
					overflow: hidden;
					border-radius: 50%;

					img {
						width: 100%;
						height: 100%;
						filter: grayscale(100%);

						&:hover {
							cursor: pointer;
							transform: scale(1.2);
							transition: all 1s;
						}
					}
				}
			}
		}

		.cloudsAndThankYouContainer {
			width: 100%;

			.cloudsAndThankYou {
				background-image: url(../../../assets/img/clouds_feather_small.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				width: 100%;
				z-index: 100;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 577px;
			}
		}

		.textContainer {
			width: $width;
			display: flex;
			flex-direction: column;
			padding-bottom: 170px;
			scroll-snap-align: end;

			.thankYou {
				color: #ffffff;
				font-family: Prata;
				font-size: 105.5px;
				letter-spacing: 0;
				line-height: 172px;
			}

			.whatsNext {
				margin-left: 120px;
				color: #ffffff;
				font-family: Prata;
				font-size: 70px;
				letter-spacing: 0;
				line-height: 163px;
			}
		}
	}

	.patientPage {
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		border: 13px solid #f8aa9e;
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		z-index: 1000;

		> .closeIconContainer {
			position: absolute;
			right: 20px;
			top: 20px;
			width: 24px;
			height: 24px;
			cursor: pointer;

			> img {
				width: 100%;
			}
		}

		> .paginatorContainer {
			position: absolute;
			right: 36px;
			bottom: 36px;
			display: flex;
			flex-direction: row;

			> .button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-right: 35px;
				cursor: pointer;

				& ~ .button {
					margin: 0;
				}

				> span {
					color: #6e6f73;
					font-family: Mulish;
					font-size: 11.5px;
					letter-spacing: 4.2px;
					line-height: 15px;
				}

				> img {
					width: 8px;
					margin-right: 6.5px;
					margin-left: 2.5px;
				}
			}
		}

		> .side {
			flex: 1;
			margin: 50px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			overflow: auto;

			& ~ .side {
				margin-left: 0;
			}

			&.side1 {
				background-image: url(../../../assets/img/watercolor-salmon-blur.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}

			&.side2 {
				background-image: url(../../../assets/img/watercolor-salmon.png);
				background-repeat: no-repeat;
				background-position-x: 66%;
			}

			> .profile {
				display: flex;
				flex-direction: row;
				margin-bottom: 35px;

				> .avatarContainer {
					width: 152px;
					height: 152px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;

					> img {
						filter: grayscale(100%);
						width: 100%;
					}
				}

				> .titles {
					display: flex;
					flex-direction: column;
					margin-left: 33px;
					align-items: flex-start;

					> .name {
						color: #6e6f73;
						font-family: Prata;
						font-size: 50px;
						letter-spacing: 0;
						line-height: 82px;
						text-decoration: underline;
						text-decoration-color: #f8aa9e;
					}

					> .ethnic {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 18px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 23px;
						text-align: center;
						text-transform: uppercase;
					}

					> .selfDefinition {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 18px;
						letter-spacing: 0;
						line-height: 23px;
						text-align: center;
						text-transform: uppercase;
					}
				}
			}

			> .contentContainer {
				display: flex;
				flex-direction: row;
				flex: 1;

				> .textContainer {
					display: flex;
					flex-direction: column;
					margin-right: 34px;

					> .wordsILiveBy,
					> .myRelationshipWithMyHCP,
					> .whatMyBiographyWouldSay {
						display: flex;
						flex-direction: column;

						> h1 {
							color: #6e6f73;
							font-family: Mulish;
							font-size: 15px;
							font-weight: bold;
							letter-spacing: 0;
							line-height: 19px;
							text-transform: uppercase;
						}

						> span {
							color: #6e6f73;
							font-family: Mulish;
							font-size: 15px;
							letter-spacing: 0;
							line-height: 22.5px;
						}
					}

					> .myRelationshipWithMyHCP {
						margin: 36px 0;
					}
				}

				> .mediaContainer {
					display: flex;
					flex-direction: column;
					width: 250px;
					justify-content: flex-end;

					> .mediaImageContainer {
						width: 250px;

						> img {
							width: 100%;
						}
					}

					> span {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 12.2px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 15px;
						text-transform: uppercase;
						margin-top: 28px;
						margin-bottom: 15px;
					}

					> .mediaSongContainer {
						width: 250px;
					}
				}
			}

			> .phraseContainer {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-left: 16px;

				> span {
					color: #6e6f73;
					font-family: Mulish;
					font-size: 15px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 19px;
					text-align: center;
					text-transform: uppercase;
				}

				> h1 {
					color: #f8aa9e;
					font-family: Prata;
					font-size: 24px;
					font-weight: normal;
					letter-spacing: 0;
					line-height: 40px;
				}
			}

			> .letterToSelf {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 5vh;
				flex-grow: 1;

				> .title {
					color: #6e6f73;
					font-family: Ephesis;
					font-size: 50px;
					letter-spacing: 0;
					line-height: 34px;
					margin-left: 5px;
				}

				> .letterToMy {
					margin-top: 25px;
					margin-left: 16px;

					> .titleContainer {
						width: 170px;
						background-color: #fbd5cb;
						color: #6e6f73;
						font-family: Mulish;
						font-size: 15px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 19px;
						text-transform: uppercase;
						padding: 3px 8px;
						margin-bottom: 20px;
					}

					> span {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 15px;
						letter-spacing: 0;
						line-height: 22.5px;
					}
				}
			}

			> .symptomsContainer {
				display: flex;
				flex-direction: row;
				flex: 1;
				justify-content: space-between;

				> .textContainer {
					display: flex;
					flex-direction: column;
					margin-left: 16px;
					margin-right: 25px;

					> h1 {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 15px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 19px;
						text-transform: uppercase;
					}

					> span {
						color: #6e6f73;
						font-family: Mulish;
						font-size: 15px;
						letter-spacing: 0;
						line-height: 20px;
					}
				}

				> .mediaContainer {
					display: flex;
					align-items: flex-end;
					width: 140px;

					> .mediaContainerImage {
						height: 140px;
						width: 140px;
						border-radius: 50%;
						overflow: hidden;
						margin-bottom: 70px;

						> img {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
