.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	position: relative;

	.middleWord {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
		font-family: Prata;
		font-size: 69.5px;
		letter-spacing: 0;
		line-height: 113px;
		color: white;
	}

	.card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: white;
		position: relative;

		&:hover {
			cursor: pointer;
		}

		.word {
			font-family: Prata;
			font-size: 69.5px;
			letter-spacing: 0;
			line-height: 113px;
		}

		.coloredWord {
			color: #FFF0BE;
		}

		.text {
			position: absolute;
			top: 85px;
			width: 80%;
			font-family: Prata;
			font-size: 21.4px;
			letter-spacing: 0;
			line-height: 32px;
			text-align: center;
		}
	}
}
