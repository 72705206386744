@import "../../assets/styles/global.scss";

.container {
	width: $width;
	height: 700px;
	display: flex;
	flex-direction: column;

	.middleSection {
		flex: 1;
		display: flex;
		align-items: center;
		margin-top: 60px;
		width: 90%;
		background-repeat: no-repeat;
		background-position-x: right;
	}
}
