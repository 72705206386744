@import "../../assets/styles/global.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	> .firstSection {
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		> .aboutStudy {
			height: 100vh;
			background-image: url(../../assets/img/watercolor_yellow.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto;
			scroll-snap-align: start;

			> .title {
				margin-top: 254px;
				height: 247px;
				width: $width;
				color: #6d6e71;
				font-size: 89.5px;
				letter-spacing: 0;
				line-height: 106px;
				text-align: left;
			}

			> .scrollDownImageContainer {
				width: $width;
				text-align: center;
				margin-top: 110px;
				margin-bottom: 220px;
				filter: invert(44%) sepia(1%) saturate(1255%) hue-rotate(188deg)
					brightness(97%) contrast(92%);

				&:hover {
					cursor: pointer;
				}
			}
		}

		> .universalTruths {
			width: $width;
			background-image: url(../../assets/img/watercolor_yellow_upsidedown.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto;
			height: 100vh;
			scroll-snap-align: start;
			justify-content: center;
			display: flex;
			flex-direction: column;

			> .title {
				width: 700px;
				color: #6d6e71;
				font-family: Prata;
				font-size: 69.5px;
				letter-spacing: 0;
				line-height: 81px;
			}

			> .text {
				color: #6d6e71;
				font-family: Mulish;
				font-size: 38.12px;
				font-weight: 500;
				letter-spacing: 0.3px;
				line-height: 54px;
			}
		}

		> .deepMetaphors {
			width: $width;
			height: 100vh;
			scroll-snap-align: start;
			display: flex;
			flex-direction: column;
			justify-content: center;

			> .title {
				width: 900px;
				color: #6d6e71;
				font-family: Prata;
				font-size: 44.8px;
				letter-spacing: 0;
				line-height: 63px;
			}

			> .imagesContainer {
				display: flex;
				flex-direction: column;

				> .imagesRow {
					margin-top: 33px;
					display: flex;
					flex: 1;
					justify-content: space-between;

					> .imageContainer {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						max-width: 212px;

						> .imageWrapper {
							width: 100%;

							> img {
								width: 100%;
							}
						}

						> span {
							color: #6d6e71;
							font-family: Mulish;
							font-size: 16px;
							font-weight: 500;
							letter-spacing: 3.9px;
							line-height: 20px;
							text-transform: uppercase;
						}
					}
				}
			}

			> .goBackLink {
				margin-top: 85px;
			}
		}

		> .whatWeKnow {
			width: $width;
			height: 100vh;
			scroll-snap-align: start;
			display: flex;
			justify-content: center;
			flex-direction: column;

			> .title {
				width: 673px;
				color: #6d6e71;
				font-family: Prata;
				font-size: 69.5px;
				letter-spacing: 0;
				line-height: 81px;
			}

			> .columns {
				display: flex;
				flex-direction: row;
				background-image: url(../../assets/img/watercolor_yellow.png);
				background-repeat: no-repeat;
				background-position: bottom;
				background-size: contain;

				> .column {
					flex: 50vw;

					> .title {
						color: #b1866d;
						font-family: Mulish;
						font-size: 28px;
						font-weight: 600;
						letter-spacing: 0.82px;
						line-height: 35px;
						margin-top: 50px;
						margin-bottom: 25px;
						text-transform: uppercase;
					}

					> .points {
						color: #6d6e71;
						font-family: Mulish;
						font-size: 29px;
						letter-spacing: 0.23px;

						> ul {
							margin: 0;
							padding: 0;
							list-style-type: none;

							> li {
								text-indent: -10px;
								margin-bottom: 20px;

								&:before {
									content: "-";
									text-indent: -10px;
								}
							}
						}
					}
				}
			}
		}

		> .weSpoke {
			width: 100%;
			height: 100vh;
			background-image: url(../../assets/img/watercolor_yellow_upsidedown.png);
			background-repeat: no-repeat;
			background-position: left bottom;
			background-size: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			scroll-snap-align: start;

			> .weSpokeInternal {
				width: $width;

				> .title {
					width: 1040px;
					color: #6d6e71;
					font-family: Prata;
					font-size: 69.5px;
					letter-spacing: 0;
					line-height: 81px;
				}

				> .content {
					padding-top: 50px;
					display: flex;
					flex: 1;

					> .imageContainer {
						height: 490px;
						width: 349.57px;

						> img {
							width: 100%;
						}
					}

					> .text {
						color: #6d6e71;
						font-family: Mulish;
						font-size: 29px;
						letter-spacing: 0.23px;
						line-height: 39px;
						margin-left: 53px;

						> .title {
							margin-top: 0;
							color: #b1866d;
							font-family: Mulish;
							font-size: 28px;
							font-weight: 600;
							letter-spacing: 0.82px;
							line-height: 35px;
						}
					}
				}
			}
		}

		> .credits {
			width: $width;
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			scroll-snap-align: start;

			> .title {
				color: #6d6e71;
				font-family: Prata;
				font-size: 69.5px;
				letter-spacing: 0;
				line-height: 81px;
			}

			> .text {
				margin: 40px 0;
				width: 625px;
				color: #6d6e71;
				font-family: Mulish;
				font-size: 29px;
				letter-spacing: 0.23px;
				line-height: 56px;

				> a {
					color: #6d6e71;

					&:hover {
						color: lighten(#6d6e71, 10%);
					}
				}
			}

			> .goBackLink {
				margin: 30px 0;
			}
		}
	}
}
