@import "../../assets/styles/global.scss";

.container {
  color: #fff;
  font-family: Mulish;
  font-size: 34.5px;
  letter-spacing: 12.2px;
  line-height: 44px;
  text-transform: uppercase;
}
