@import "../../assets/styles/global.scss";

// .underline {
//   text-decoration-line: underline;
//   text-decoration-style: solid;
//   text-decoration-thickness: 16px;
//   text-underline-offset: -10px;
//   // background-image: linear-gradient(180deg, white, white);
// }
:root {
	/* The intrinsic width of the underline stroke (in pixels). This is
   * the same as the height of the cap images. Don't specify the
   * units! This is because of some of the calculations we do later on. */
	--underline-intrinsic-width: 80;

	/* The actual width of the underline stroke we want to render (in pixels).
   * You can modify this, and the sizing and positioning should be calculated
   * accordingly. Again, Don't specify the units! */
	--underline-width: 12;

	/* We need to know the width of the cap images so that we
   * can position everything on the x axis accordingly. */
	--underline-cap-width: 4px;

	/* The border is positioned relative to the bottom of the line.
   * We can move it upwards a little to create an overlap effect. */
	// --underline-offset-y: -2px;

	/* The padding to add to the x axis. By default, the caps would be
   * aligned with the beginning and end of the line. */
	--underline-padding-x: 0.12em;
}

.underline {
	padding: 0
		calc(
			var(--underline-padding-x) +
				calc(var(--underline-cap-width) * var(--underline-width-scale))
		);
	display: inline;
	--underline-width-scale: calc(
		var(--underline-width) / var(--underline-intrinsic-width)
	);
	box-decoration-break: clone;
	background-repeat: no-repeat;
	background-position-x: calc(
			var(--underline-cap-width) * var(--underline-width-scale)
		),
		0, 100%;
	background-position-y: calc(100% - var(--underline-offset-y) * -1);
	background-size: calc(
				100% -
					calc(
						var(--underline-cap-width) *
							var(--underline-width-scale) * 2
					)
			)
			calc(var(--underline-width) * 1px),
		auto calc(var(--underline-width) * 1px),
		auto calc(var(--underline-width) * 1px);

	// font-size: 3rem;
	--underline-width: 20;
	--underline-offset-y: -2px;
}
