html {
	height: 100vh;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: Prata, serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-snap-type: y proximity;
	height: 100vh;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

$scroll-background: #d0d4d5;
$scroll-hover-background: #c3c3c3;

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $scroll-background;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $scroll-hover-background;
}
