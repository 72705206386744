@import "../../assets/styles/global.scss";

.container {
	display: flex;
	align-items: flex-start;
	color: white;

	&:hover {
		cursor: pointer;
		color: #fff0be;

		.plusContainer {
			transform: rotate(180deg);
			transition-duration: 1s;
			filter: none;
		}
	}

	.plusContainer {
		display: flex;
		transform: translate(0px, 0px);
		filter: invert(0%) sepia(3%) saturate(21%) hue-rotate(306deg)
			brightness(105%) contrast(100%);
	}

	.signature {
		font-family: Mulish;
		font-weight: 500;
		letter-spacing: 5.3px;
		display: block;
		margin-left: 16px;
		text-transform: uppercase;
	}
}
