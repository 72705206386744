.container {
	display: flex;
	cursor: pointer;

	> img {
		margin-right: 8px;
	}

	> span {
		color: #6d6e71;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 5.5px;
		line-height: 20px;

		&:hover {
			text-decoration: underline;
			text-underline-offset: 8px;
		}
	}
}
