.container {
	display: flex;
	box-sizing: border-box;
	border: 1px solid #ffffff;
	padding: 12px;
	border-radius: 20px;
	width: unset;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		margin-right: 12px;
	}

	.title {
		color: #ffffff;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 3px;
		line-height: 20px;
		text-transform: uppercase;
	}
}
