@import "../../assets/styles/global.scss";

.container {
	max-width: 975px;
	color: #fff;
	font-family: Prata;
	font-size: 105px;
	letter-spacing: 0;
	line-height: 170px;
}
